import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
export default function FallbackAvatars(props) {
  const { img, width, height } = props;
  return (
    <div style={{ display: "flex" }}>
      <Stack direction="row" spacing={2}>
        <Avatar
          src={img ? img : ""}
          sx={{ width: { width }, height: { height } }}
        />
      </Stack>
    </div>
  );
}
