import React, { useEffect } from "react";
import lottie from "lottie-web";
import reactLogo from "./lf30_editor_tsz6unke.json";

import "./_index.scss";
const LoadingScreen = () => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
      animationData: reactLogo,
      loop: true,
      autoplay: true,
    });
  }, []);

  return (
    <div className="loading-screen">
      <div className="loading-screen-container">
        <div id="react-logo" className="react-logo" />
      </div>
    </div>
  );
};

export default LoadingScreen;
